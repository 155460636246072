$frame-padding: 20px;
$frame-padding-bottom: 10px;
$frame-corner-radius: 4px;
$frame-colour: white;


.artwork-frame {
    position: absolute;
    z-index: 999;

    display: flex;
    flex-direction: column;

    background-color: $frame-colour;
    color: black;
    border-radius: $frame-corner-radius;
    
    padding: $frame-padding;
    padding-bottom: 0;

    box-shadow: 0 4px 16px rgba(0, 0, 0, 0.16);

    & .artwork {
        display: block;
        max-width: 720px;
        max-height: 400px;
        border-radius: #{$frame-corner-radius/2};
    }

    & .lower-third {
        display: flex;
        margin: $frame-padding-bottom 0;

        & .logo-gif {
            height: 100px;
        }

        & .artwork-info {
            flex-grow: 1;

            & p {
                padding: 0;
                margin: 0;
                margin-top: $frame-padding-bottom;
                text-align: left;
            }

            & .artwork-title {
                font-size: 42px;
            }

            & .artwork-author {
                font-size: 24px;

                &.big {
                    font-size: 42px;
                }
            }
        }
    }
}