.App-header {
  background-color: #002d3b;
  min-height: 100vh;
}

.main-logo-gif {
  position: absolute;
  height: 650px;
  top: 215px;
  left: 588.375px;

  opacity: 0;
}

.background-image {
  position: absolute;
  top: 0;
  left: 0;
}